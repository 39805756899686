export var baseHost = {
  flowURL: `${window.location.protocol}//service.cancon.com.cn/api/workFlow`,
  SupplierRegHost: `${window.location.protocol}//service.cancon.com.cn`
}

export var tokenHeader = {
  token: localStorage.getItem('token')
}

export var downloadByBlob = function (data, fileName, mime) {
  try {
    const url = window.URL.createObjectURL(data)
    const eleLink = document.createElement('a')
    eleLink.href = url
    eleLink.target = '_blank'
    if (mime.indexOf('image') < 0 && mime.indexOf('pdf') < 0) {
      eleLink.download = fileName
    }
    eleLink.click()
    window.URL.revokeObjectURL(url)
  } catch (error) {
    console.error('download function error!', error)
  }
}
