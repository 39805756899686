<template>
  <div>
    <list-search :childData="childData" @onSearch="onSearch" @clearSearch="clearSearch"></list-search>
    <div class="tableHeader" style="font-size:16px">考核周期：{{assessCycle}}</div>
    <supplier-lcmtable :tableData="tableData"> </supplier-lcmtable>
    <div class="tablepage">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size = pageSize
        layout="total,  prev, pager, next, jumper"
        :total=total>
      </el-pagination>
    </div>
  </div>
</template>

<script>
import ListSearch from '@/components/actionList/ListSearch.vue'
import { SearchList, SearchData } from '@/views/mainDataSupplier/lifecycle/js/Lifecycle.js'
import SupplierLcmtable from './SupplierLcmtable.vue'
import { request } from '@/assets/js/http.js'
export default {
  name: 'SupplierLcm',
  components: { ListSearch, SupplierLcmtable },
  data: function () {
    var pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      childData: { searchList: SearchList, searchData: SearchData },
      pageSize: pageSize,
      pageNum: 1,
      dialogShow: false,
      total: 0, // 共多少条
      tableData: [],
      assessCycle: ''
    }
  },
  mounted () {
    this.SupplierLcmList()
  },
  methods: {

    SupplierLcmList () {
      var pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
      if (pageSize < 10) {
        pageSize = 10
      }
      const obj = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        providerInfo: this.childData.searchData.providerInfo,
        materialType: this.childData.searchData.materialType,
        providerLevel: this.childData.searchData.providerLevel,
        approvalStatus: this.childData.searchData.approvalStatus
      }
      request('/api/supplier/supplierLevel/getDataList', 'get', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.dataList.records
          this.assessCycle = res.data.assessCycle
          this.total = res.data.dataList.total
        }
      })
    },
    handleClose () {
      this.dialogShow = false
    },
    // 查询
    onSearch (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] === 'All' || data[key] === '') {
          delete data[key]
        }
      })
      this.pageNum = 1
      this.SupplierLcmList()
    },
    // 清空
    clearSearch (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] === 'All' || data[key] === '') {
          delete data[key]
        }
      })
      this.pageNum = 1
      this.SupplierLcmList()
    },
    // 每页显示多少条
    handleSizeChange (val) {
      this.pageSize = val
      this.SupplierLcmList()
    },
    // 当前第几页
    handleCurrentChange (val) {
      this.pageNum = val
      this.SupplierLcmList()
    }
  }
}

</script>

<style scoped lang="scss">
.tableHeader{
  color: #606266;
  font-size: 16px;
  padding: 8px 30px;
  font-size: 14px;
  line-height: 30px;
}
  .tablepage{
    padding: 18px 0;
    text-align: center;
  }
</style>
