export var SearchList = [
  { labe: '供应商', code: 'providerInfo', type: 'input' },
  {
    labe: '物料品类',
    code: 'materialType',
    type: 'select',
    option: [
      { label: '主板', value: '主板' },
      { label: 'CPU', value: 'CPU' },
      { label: '硬盘', value: '硬盘' },
      { label: '内存', value: '内存' },
      { label: '板卡', value: '板卡' },
      { label: '电源', value: '电源' },
      { label: '风扇', value: '风扇' },
      { label: '散热器', value: '散热器' },
      { label: '线缆', value: '线缆' },
      { label: '交换机', value: '交换机' },
      { label: '磁盘阵列', value: '磁盘阵列' },
      { label: 'OEM安全产品', value: 'OEM安全产品' },
      { label: '商品', value: '商品' },
      { label: '包材', value: '包材' },
      { label: '结构件', value: '结构件' },
      { label: '光模块', value: '光模块' },
      { label: '软件', value: '软件' },
      { label: '固定资产', value: '固定资产' },
      { label: '外设', value: '外设' },
      { label: '云计算集成外包', value: '云计算集成外包' },
      { label: '外协', value: '外协' },
      { label: '元器件', value: '元器件' },
      { label: '其它', value: '其它' }
    ]
  },
  {
    labe: '供应商级别',
    code: 'providerLevel',
    type: 'select',
    option: [
      { label: '全部', value: 'All' },
      { label: '一次', value: '1' },
      { label: '一般', value: '2' },
      { label: '合格', value: '3' },
      { label: '淘汰', value: '4' }
    ]
  },
  {
    labe: '状态',
    code: 'approvalStatus',
    type: 'select',
    option: [
      { label: '全部', value: 'All' },
      { label: '待确认', value: '1' },
      { label: '审批中', value: '2' },
      { label: '驳回', value: '3' },
      { label: '已调整', value: '4' }
    ]
  }

]
export var SearchData = {
  providerInfo: '',
  materialType: '',
  providerLevel: '',
  approvalStatus: ''
}
export var tableData = []
export var tableField = [
  { label: '订单号', code: 'orderCode', type: 'input', width: '' },
  { label: '合同编号', code: 'contractCode', type: 'input', width: '' },
  { label: '订单金额(万元)', code: 'dealSumNum', type: 'input', width: '' }
]
