<template>
  <div class="tableList">
    <div class="table">
      <el-table :data="tableData" ref="table" stripe style="width: 100%">
        <el-table-column prop="providerCode" label="供应商编码" :show-overflow-tooltip="true"> </el-table-column>
        <el-table-column prop="providerName" label="供应商名称" :show-overflow-tooltip="true" width="250px"> </el-table-column>
        <el-table-column prop="materialType" label="物料品类" :show-overflow-tooltip="true"> </el-table-column>
        <el-table-column prop="businessScope" label="经营范围" :show-overflow-tooltip="true"> </el-table-column>
        <el-table-column prop="type" label="级别" :show-overflow-tooltip="true">
          <template #default="scope">
            {{scope.row.type=='1'?'一次':scope.row.type=='2'?'一般':scope.row.type=='3'?'合格':'淘汰'}}
          </template>
        </el-table-column>
        <el-table-column  label="调整依据" :show-overflow-tooltip="true" width="250px">
          <template #default="scope">
            <el-button size="mini" type="text" @click="handleShow(scope.$index, scope.row)">
              <div class="gist">交易次数：{{scope.row.dealNumber}}次</div>
              <div class="gist" style="margin-top: 5px;">累计金额（万元）：{{scope.row.dealAmount}}W</div>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="adjustAdvice" label="调整建议" width="150px">
          <template #default="scope">
            {{scope.row.adjustAdvice=='1'?'一次升级到一般':scope.row.adjustAdvice=='2'?'一次升级到合格':scope.row.adjustAdvice=='3'?'一般升级到合格'
            :scope.row.adjustAdvice=='4'?'一般降级到一次':scope.row.adjustAdvice=='5'?'合格降级到一般':scope.row.adjustAdvice=='6'?'合格降级到淘汰'
            :scope.row.adjustAdvice=='7'?'一次降级到淘汰':scope.row.adjustAdvice=='8'?'一般降级到淘汰':scope.row.adjustAdvice}}
          </template>
        </el-table-column>
        <el-table-column  align="center" prop="approvalStatus" label="状态" >
          <template #default="scope">
            {{scope.row.approvalStatus=='1'?'待确认':scope.row.approvalStatus=='2'?'审批中':scope.row.approvalStatus=='3'?'驳回' : '已调整'}}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
              <el-button v-if="scope.row.approvalStatus !== '4'" type="text" size="mini" @click="confirmadjustment(scope.$index, scope.row)">确认调整</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 调整依据弹框 -->
    <dialog-box :tableData="tableData" dialogWidth="50%" :adjustmentData ="adjustmentData"   :dialogShow="adjustmentdialogShow" @handleClose='handleClose'  title="调整依据"
    :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{ label: '确认', action: 'handleClose', size: 'mini',type: 'primary' }]" componentName="Supplementdialog"></dialog-box>
    <!-- 二次确认弹窗  供应商级别调整确认-->
    <dialog-box :bjfzrsplist="bjfzrsplist" :bjfzr="bjfzr" :showData = "showData" dialogWidth="750px" :dialogShow="dialogShow" @handleClose='handleClose'  @confirmadj="confirmadj" title="供应商级别调整确认"
    :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{ label: '确认', action: 'confirmadj', size: 'mini',type: 'primary' }]"
    componentName="LifecycleDialog"></dialog-box>
    <!-- 上传资质文件弹窗 v-model="supplement"-->
    <el-dialog title="请补充资质文件"  width="50%" v-model="supplement">
      <div class="remark">注:升级为合格供应商需具备"品质认证文件"和"产品技术文件"</div>
      <el-form class="content bascform" ref="form" :model="form" label-width="140px">
          <div class="col col4">
            <el-form-item label="品质认证文件" v-model="form.qualityFiles" prop="qualityFiles" :rules="{ required: true, message: '请上传品质认证文件', trigger: 'blur' }">
              <el-upload
              :action="actionURL"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              :headers="tokenHeader"
              :on-success="handleSuccess"
              :file-list="form.qualityFiles">
              <el-button   size="small" type="primary">+上传文件</el-button>
            </el-upload>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="产品技术文件" v-model="form.productFiles" prop="productFiles" :rules="{ required: true, message: '请上传产品技术文件', trigger: 'blur' }">
              <el-upload
              :action="productFileURL"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              :headers="tokenHeader"
              :on-success="productFileSuccess"
              :file-list="form.productFiles">
              <el-button   size="small" type="primary">+上传文件</el-button>
            </el-upload>
            </el-form-item>
          </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="mini" @click="cancel">取 消</el-button>
          <el-button size="mini" type="primary" @click="confirmUpload">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import DialogBox from '@/components/plug/DialogBox.vue'
import { request, exportForm } from '@/assets/js/http.js'
import { MIME } from '@/assets/js/mime.js'
import { baseHost } from '@/assets/js/PublicData.js'
import { FlowCtlData } from '@/assets/js/FlowManage.js'
export default {
  name: 'SupplierLcmtable',
  props: ['tableData'],
  components: { DialogBox },
  data: function () {
    FlowCtlData.flowid = 'Lifecycle'
    FlowCtlData.initialize()
    return {
      form: {
        qualityFiles: [],
        productFiles: []
      },
      dialogShow: false,
      adjustmentdialogShow: false,
      supplement: false,
      showData: {},
      bjfzrsplist: [],
      cpyzlbhq: '',
      cgbjlsp: '',
      bjfzr: { data: '' },
      adjustmentData: {},
      tokenHeader: {
        token: localStorage.getItem('token')
      },
      actionURL: baseHost.SupplierRegHost + '/api/file/m/file/uploadToServer',
      productFileURL: baseHost.SupplierRegHost + '/api/file/m/file/uploadToServer'
    }
  },
  created () {
    FlowCtlData.getApprPsn('561052060826472448').then((val) => { this.bjfzrsplist = val.split(';') })
    FlowCtlData.getApprPsn('554180635259949056').then((val) => { this.cpyzlbhq = this.cpyzlbhq + val })
    FlowCtlData.getApprPsn('542244799739269120').then((val) => { this.cpyzlbhq = this.cpyzlbhq + val })
    FlowCtlData.getApprPsn('551625315191681024').then((val) => { this.cgbjlsp = val })
  },
  methods: {
    confirmadjustment: function (index, data) {
      this.showData = { ...data }
      request('/api/supplier/supplierLevel/checkFile/?providerCode=' + data.providerCode, 'GET', '').then((Response) => {
        if (Response.code === '200') {
          this.form = Response.data
          if (data.adjustAdvice === '1' || data.adjustAdvice === '4' || data.adjustAdvice === '5' || data.adjustAdvice === '6' || data.adjustAdvice === '7' || data.adjustAdvice === '8') {
            this.dialogShow = true // 二次弹框
          } else if (data.adjustAdvice === '2' || data.adjustAdvice === '3') {
            if (Response.data.productFiles.length === 0 || Response.data.qualityFiles.length === 0) {
              this.supplement = true // 资质弹框
            } else {
              this.dialogShow = true// 二次弹框
            }
          }
        }
      })
    },
    handleClose () {
      this.dialogShow = false
      this.adjustmentdialogShow = false
    },
    confirmadj () { // 二次弹框确认按钮
      FlowCtlData.docid = this.showData.id
      let issp = false
      this.showData.cgbjlsp = this.cgbjlsp
      this.showData.cpyzlbhq = this.cpyzlbhq
      this.showData.bjfzrsp = this.bjfzr.data
      if (this.showData.adjustAdvice === '1') {
        issp = true
        this.showData.ishg = 'n'
      } else if (this.showData.adjustAdvice === '2' || this.showData.adjustAdvice === '3') {
        issp = true
        this.showData.ishg = 'y'
      } else if (this.showData.adjustAdvice === '6' || this.showData.adjustAdvice === '7' || this.showData.adjustAdvice === '8') {
        issp = true
        this.showData.ishg = 'n'
      } else {
        request('/api/supplier/supplierLevel/confirmAdjust/?id=' + this.showData.id + '&approval=3', 'GET').then((Response) => {
          if (Response.code === '200') {
            this.$message({
              type: 'success',
              message: '调整成功！'
            })
          }
        })
      }
      if (issp) {
        FlowCtlData.getNodeAction('TJ', this.showData).then((val) => {
          if (val) {
            FlowCtlData.alertDialog(this).then(() => {
              FlowCtlData.setFormData(FlowCtlData, this.showData)
              request('/api/supplier/supplierLevel/confirmAdjust/?id=' + this.showData.id + '&approval=3', 'GET').then((Response) => {
                if (Response.code === '200') {
                  FlowCtlData.setNodeAction().then((val) => {
                    FlowCtlData.formAlert(this, 'success')
                  })
                }
              })
            }).catch(() => { FlowCtlData.formAlert(this, 'info') })
          }
        }).catch(() => { FlowCtlData.formAlert(this, 'error') })
      }
      this.dialogShow = false
    },
    handleShow: function (index, data) {
      this.adjustmentdialogShow = true
      this.adjustmentData = { ...data }
    },
    // 第二次弹窗的取消操作
    cancel () {
      this.supplement = false
    },
    confirmUpload () { // 资质文件弹框确认
      var isPass = true
      this.$refs.form.validate((valid) => {
        if (!valid) {
          isPass = false
        }
      })
      if (!isPass) {
        this.$message({
          message: '请上传文件',
          type: 'error'
        })
        return false
      }
      var providerCodeData = this.showData.providerCode
      var obj = {
        providerCode: providerCodeData,
        productFiles: this.form.productFiles,
        qualityFiles: this.form.qualityFiles
      }
      request('/api/supplier/supplierLevel/saveFile', 'post', obj).then((Response) => {
        if (Response.code === '200') {
          this.$message({
            type: 'success',
            message: '文件上传成功'
          })
        }
      })
      this.supplement = false
      this.dialogShow = true
    },
    handlePreview (file) {
      exportForm('/api/file/m/file/downloadFile/' + file.id, 'get').then(
        (response) => {
          var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
          var mime = 'application/octet-stream'
          if (MIME[fileExtension]) {
            mime = MIME[fileExtension]
          }
          const aBlob = new Blob([response.data], { type: mime })
          this.downloadByBlob(aBlob, file.name, mime)
        })
    },
    uploadFileBtn () {
      this.handlePreview(this.fieldList)
    },
    handleRemove (file) {
      for (var i = 0; i < this.form.qualityFiles.length; i++) {
        if (this.form.qualityFiles[i].id === file.id) {
          this.form.qualityFiles.splice(i, 1)
        }
      }
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleSuccess (response, file, fileList) {
      if (response.code === '200') {
        response.data.forEach(element => {
          this.form.qualityFiles.push(element)
        })
      }
    },
    productFileSuccess (response, file, fileList) {
      if (response.code === '200') {
        response.data.forEach(element => {
          this.form.productFiles.push(element)
        })
      }
    },
    // 上传失败提示
    handError () {
      this.$notify.error({
        title: '错误',
        message: '文件上传失败'
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
@import '@/assets/css/tablePadding.scss';
.remark {
  color: #868686;
  margin: 15px 30px;
}
.tableList{
  margin: 0px;
  :deep(.table){
  .el-table td {
    padding: 4px 0;
  }
  .gist{
    text-align: left;
  }
}
:deep(.el-dialog__header){
    padding: 15px 20px 15px;
    border-bottom: 1px solid #e4e4e4;
  }
:deep(.el-dialog__footer){
    padding: 15px 20px;
    border: 1px solid #e4e4e4;
  }
:deep(.el-overlay){
    justify-content: center;
    align-items: center;
    display: flex;
  }
}
</style>
