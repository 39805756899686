<template>
  <div class="dialogBox">
    <el-dialog top="0px" :custom-class="customClass" :title="title" :model-value="dialogShow" :append-to-body="appendToBody" :lock-scroll="lockScroll" :width="dialogWidth||'30%'" destroy-on-close
      :before-close="handleClose" :show-close="showClose" :fullscreen="fullscreen||false">
      <div class="content">
        <component ref="content" :is="componentName" v-bind="$attrs"></component>
      </div>
      <template #footer>
        <div class="dialog-footer" :class="buttonAlign">
          <el-button :key="key" v-for="(itme,key) in buttonData" @click="buttonClick(itme)" :type="itme.type" :plain="itme.plain"
            :size="itme.size">
            {{itme.label}}
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
export default {
  name: 'DialogBox',
  components: Component.components,
  props: ['dialogShow', 'showClose', 'title', 'dialogWidth', 'fullscreen', 'componentName', 'buttonData', 'buttonAlign', 'lockScroll', 'appendToBody', 'customClass'],
  inheritAttrs: false,
  methods: {
    buttonClick (data) {
      this.$emit(data.action, data)
    },
    handleClose (done) {
      this.$emit('handleClose', done)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/dialogBox.scss';
</style>
