/* eslint-disable no-undef */
/* eslint-disable no-redeclare */
import { requestForm } from '@/assets/js/http.js'
import axios from 'axios'
import qs from 'qs'
import { baseHost } from './PublicData.js'
import { ElLoading, ElMessage } from 'element-plus'
import buriedPoint from './buriedPoint'
let loading = {}
export var FlowCtlData = {
  flowAppDetail: [],
  flowMapData: {},
  flowInfo: {},
  loginID: '',
  initialized: 0,
  tmpNextMsgInfo: {},
  nodeThisData: [],
  formdata: {},
  docid: '',
  flowid: '',
  setFlowDetai: '',
  setThisFlowInfo: '',
  initialize: async function () {
    this.tmpNextMsgInfo = {}
    this.nodeThisData = []
    this.formdata = {}
    this.flowAppDetail = []
    this.flowMapData = {}
    this.flowInfo = {}
    this.loginID = ''
    axios.post(baseHost.flowURL + '/getThisFlowMsg',
      qs.stringify({ type: 'getFlowDetai', flowId: this.flowid, docId: this.docid })
      , { headers: { businessScope: sessionStorage.getItem('businessScope'), CurrentLogonPsn: this.loginID, token: localStorage.getItem('token') } }).then(
      (response) => {
        this.flowAppDetail = response.data
        if (typeof this.setFlowDetai === 'function') {
          try {
            this.setFlowDetai(response.data)
          } catch (e) {
            console.log(e)
          }
        }
        this.initialized = this.initialized + 1
        buriedPoint(response)
      }).catch((e) => {
      this.initialized = this.initialized + 1
      buriedPoint(e)
    })
    this.loginID = localStorage.getItem('userName')
    this.initialized = this.initialized + 1
    axios.post(baseHost.flowURL + '/getFlowMsg', qs.stringify({ type: 'getNodeInfo', id: this.flowid })
      , { headers: { businessScope: sessionStorage.getItem('businessScope'), CurrentLogonPsn: this.loginID, token: localStorage.getItem('token') } }).then(
      (response) => {
        this.flowInfo = response.data
        this.initialized = this.initialized + 1
        buriedPoint(response)
      }).catch((e) => {
      this.initialized = this.initialized + 1
      buriedPoint(e)
    })
    await axios.post(baseHost.flowURL + '/getThisFlowMsg', qs.stringify({ type: 'getThisNodeDetai', flowId: this.flowid, docId: this.docid })
      , { headers: { businessScope: sessionStorage.getItem('businessScope'), CurrentLogonPsn: this.loginID, token: localStorage.getItem('token') } }).then(
      (response) => {
        this.nodeThisData = response.data
        if (response.data[0].NODEID === 'flowEnd') {
        }
        if (response.data[0].NODEID === 'flowStart') {
        }
        if (typeof this.setThisFlowInfo === 'function') {
          try {
            this.setThisFlowInfo(response.data)
          } catch (e) {
            console.log(e)
          }
        }
        this.initialized = this.initialized + 1
        buriedPoint(response)
      }).catch((e) => {
      this.initialized = this.initialized + 1
      buriedPoint(e)
    })
    axios.post(baseHost.flowURL + '/getFlowMsg', qs.stringify({ type: 'getMapInfo', id: this.flowid })
      , { headers: { businessScope: sessionStorage.getItem('businessScope'), CurrentLogonPsn: this.loginID, token: localStorage.getItem('token') } }).then(
      (response) => {
        this.flowMapData = response.data
        this.initialized = this.initialized + 1
        buriedPoint(response)
      }).catch((e) => {
      this.initialized = this.initialized + 1
      buriedPoint(e)
    })
  },
  nextNodeAllMsg: {},
  getNodeAction: async function (act, formdata) {
    if (this.initialized < 5) {
      console.log(this.initialized)
      return false
    }
    loading = ElLoading.service({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.2)'
    })
    this.formdata = formdata
    if (act === 'TJ') {
      var ft = ''
      await axios.post(baseHost.flowURL + '/getNodeMsg?flowReqType=getNextNode&docid=' + this.docid + '&flowid=' + this.flowid, qs.stringify(formdata)
        , { headers: { businessScope: sessionStorage.getItem('businessScope'), CurrentLogonPsn: this.loginID, token: localStorage.getItem('token') } }).then(
        (response) => {
          this.nextNodeAllMsg = response.data
          if (response.data.MsgType === 'error') {
            alert(response.data.ErrorMsg)
            ft = false
          } else {
            if (response.data.linkMsg[0].psnMsg.PsnVer === 'error') {
              ElMessage({
                message: '未找到审批人！',
                type: 'error'
              })
              ft = false
            } else {
              this.setNextNodeMsg(response.data.linkMsg[0], 'TJ')
              ft = true
            }
          }
          buriedPoint(response)
        }).catch((e) => {
        buriedPoint(e)
      })
      loading.close()
      return ft
    }
    if (act === 'BH') {
      this.nextflowactionmsg.nodeMsg.nodeId = 'flowStart'
      this.nextflowactionmsg.nodeMsg.nodeName = '开始'
      this.nextflowactionmsg.actMsg = act
      for (var j = 0, len = this.flowAppDetail.length; j < len; j++) {
        var _this = this.flowAppDetail[j]
        if (_this.NODESTATE === 'active') {
          this.nextflowactionmsg.nodeMsg.blockId = _this.NODEID
        }
        if (_this.NODEID === 'flowStart') {
          this.nextflowactionmsg.psnMsg.psnId = _this.APPROVER
          /* await axios.post(baseHost.flowURL + '/getPoint?extension', qs.stringify({ type: 'getPsn', getfield: 'NAME2', searchfield: "USRID='" + this.APPROVER + "'" })
            , { headers: { CurrentLogonPsn: this.loginID } }).then(
            (response) => {
              this.nextflowactionmsg.psnMsg.PsnName = response.data[0].NAME2
            }).catch(() => {
          }) */
          break
        }
      }

      loading.close()
      return true
    }
    if (act === 'BC') {
      var ft = ''
      await axios.post(baseHost.flowURL + '/setNodeMsg?docid=' + this.docid + '&flowid=' + this.flowid + "&nextFlow={'nodeMsg': {'nodeId':'','blockId':''},'psnMsg':{'psnId':'','PsnName':''},'actMsg':'CREATE'}"
        , qs.stringify(formdata), { headers: { businessScope: sessionStorage.getItem('businessScope'), CurrentLogonPsn: this.loginID, token: localStorage.getItem('token') } }).then(
        (response) => {
          if (response.data.STATE === 'ok') {
            ft = true
          }
          buriedPoint(response)
        }).catch((e) => {
        buriedPoint(e)
      })
      loading.close()
      return true
    }
    loading.close()
  },
  nextflowactionmsg: {
    nodeMsg: { nodeId: '', blockId: '' },
    psnMsg: { psnId: '', PsnName: '' },
    actMsg: '',
    ApproveMind: ''
  },
  setNextNodeMsg: function (data, act) {
    if (act === 'TJ') {
      this.tmpNextMsgInfo = data
      this.nextflowactionmsg.nodeMsg.nodeId = data.nodeMsg.nodeId
      this.nextflowactionmsg.nodeMsg.nodeName = data.nodeMsg.nodeName
      for (var j = 0, len = this.flowAppDetail.length; j < len; j++) {
        if (this.flowAppDetail[j].NODESTATE === 'active') {
          this.nextflowactionmsg.nodeMsg.blockId = this.NODEID
        }
      }
      if (data.nodeMsg.nodeId !== 'flowEnd') {
        this.nextflowactionmsg.psnMsg.psnId = data.psnMsg.psnId
        this.nextflowactionmsg.psnMsg.PsnName = data.psnMsg.psnCn
      } else {
        this.nextflowactionmsg.psnMsg.psnId = ''
        this.nextflowactionmsg.psnMsg.PsnName = ''
      }
      this.nextflowactionmsg.actMsg = act
    }
  },
  setNodeAction: async function (mind) {
    var act = this.nextflowactionmsg.actMsg
    var appmind = mind || ''
    if (act === 'TJ') {
      if (appmind.replace(/\s*/g, '') === '') {
        appmind = '同意！'
      }
    }
    if (act === 'BH') {
      if (appmind.replace(/\s*/g, '') === '') {
        alert('请输入审批意见！')
        return false
      }
    }
    if (this.nextflowactionmsg.nodeMsg.nodeId !== 'flowEnd') {
      if (this.nextflowactionmsg.psnMsg.psnId === undefined || this.nextflowactionmsg.psnMsg.psnId == null || this.nextflowactionmsg.psnMsg.psnId === '') {
        return false
      }
    }
    var appminddata = this.base64.encode(appmind)
    this.nextflowactionmsg.ApproveMind = appminddata
    var tf = false
    loading = ElLoading.service({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.2)'
    })
    await axios.post(baseHost.flowURL + '/setNodeMsg?flowReqType=getNextNode&docid=' + this.docid + '&flowid=' + this.flowid + '&nextFlow=' + encodeURIComponent(JSON.stringify(this.nextflowactionmsg))
      , qs.stringify(this.formdata), { headers: { businessScope: sessionStorage.getItem('businessScope'), CurrentLogonPsn: this.loginID, token: localStorage.getItem('token') } }).then(
      (response) => {
        if (response.data.STATE === 'ok') {
          tf = true
        }
        buriedPoint(response)
      }).catch((e) => {
      buriedPoint(e)
    })
    loading.close()
    return tf
  },
  base64: {
    // 转码表
    table: [
      'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H',
      'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P',
      'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X',
      'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f',
      'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n',
      'o', 'p', 'q', 'r', 's', 't', 'u', 'v',
      'w', 'x', 'y', 'z', '0', '1', '2', '3',
      '4', '5', '6', '7', '8', '9', '+', '/'
    ],
    UTF16ToUTF8: function (str) {
      var res = []; var len = str.length
      for (var i = 0; i < len; i++) {
        var code = str.charCodeAt(i)
        if (code > 0x0000 && code <= 0x007F) {
          // 单字节，这里并不考虑0x0000，因为它是空字节
          // U+00000000 – U+0000007F 0xxxxxxx
          res.push(str.charAt(i))
        } else if (code >= 0x0080 && code <= 0x07FF) {
          // 双字节
          // U+00000080 – U+000007FF 110xxxxx 10xxxxxx
          // 110xxxxx
          var byte1 = 0xC0 | ((code >> 6) & 0x1F)
          // 10xxxxxx
          var byte2 = 0x80 | (code & 0x3F)
          res.push(
            String.fromCharCode(byte1),
            String.fromCharCode(byte2)
          )
        } else if (code >= 0x0800 && code <= 0xFFFF) {
          // 三字节
          // U+00000800 – U+0000FFFF 1110xxxx 10xxxxxx 10xxxxxx
          // 1110xxxx
          var byte1 = 0xE0 | ((code >> 12) & 0x0F)
          // 10xxxxxx
          var byte2 = 0x80 | ((code >> 6) & 0x3F)
          // 10xxxxxx
          var byte3 = 0x80 | (code & 0x3F)
          res.push(
            String.fromCharCode(byte1),
            String.fromCharCode(byte2),
            String.fromCharCode(byte3)
          )
        } else if (code >= 0x00010000 && code <= 0x001FFFFF) {
          // 四字节
          // U+00010000 – U+001FFFFF 11110xxx 10xxxxxx 10xxxxxx 10xxxxxx
        } else if (code >= 0x00200000 && code <= 0x03FFFFFF) {
          // 五字节
          // U+00200000 – U+03FFFFFF 111110xx 10xxxxxx 10xxxxxx 10xxxxxx 10xxxxxx
        } else /** if (code >= 0x04000000 && code <= 0x7FFFFFFF) */ {
          // 六字节
          // U+04000000 – U+7FFFFFFF 1111110x 10xxxxxx 10xxxxxx 10xxxxxx 10xxxxxx 10xxxxxx
        }
      }

      return res.join('')
    },
    UTF8ToUTF16: function (str) {
      var res = []; var len = str.length
      var i = 0
      for (var i = 0; i < len; i++) {
        var code = str.charCodeAt(i)
        // 对第一个字节进行判断
        if (((code >> 7) & 0xFF) === 0x0) {
          // 单字节
          // 0xxxxxxx
          res.push(str.charAt(i))
        } else if (((code >> 5) & 0xFF) === 0x6) {
          // 双字节
          // 110xxxxx 10xxxxxx
          var code2 = str.charCodeAt(++i)
          var byte1 = (code & 0x1F) << 6
          var byte2 = code2 & 0x3F
          var utf16 = byte1 | byte2
          res.push(Sting.fromCharCode(utf16))
        } else if (((code >> 4) & 0xFF) === 0xE) {
          // 三字节
          // 1110xxxx 10xxxxxx 10xxxxxx
          var code2 = str.charCodeAt(++i)
          var code3 = str.charCodeAt(++i)
          var byte1 = (code << 4) | ((code2 >> 2) & 0x0F)
          var byte2 = ((code2 & 0x03) << 6) | (code3 & 0x3F)
          var utf16 = ((byte1 & 0x00FF) << 8) | byte2
          res.push(String.fromCharCode(utf16))
        } else if (((code >> 3) & 0xFF) === 0x1E) {
          // 四字节
          // 11110xxx 10xxxxxx 10xxxxxx 10xxxxxx
        } else if (((code >> 2) & 0xFF) === 0x3E) {
          // 五字节
          // 111110xx 10xxxxxx 10xxxxxx 10xxxxxx 10xxxxxx
        } else /** if (((code >> 1) & 0xFF) == 0x7E) */ {
          // 六字节
          // 1111110x 10xxxxxx 10xxxxxx 10xxxxxx 10xxxxxx 10xxxxxx
        }
      }

      return res.join('')
    },
    encode: function (str) {
      if (!str) {
        return ''
      }
      var utf8 = this.UTF16ToUTF8(str) // 转成UTF8
      var i = 0 // 遍历索引
      var len = utf8.length
      var res = []
      while (i < len) {
        var c1 = utf8.charCodeAt(i++) & 0xFF
        res.push(this.table[c1 >> 2])
        // 需要补2个=
        if (i === len) {
          res.push(this.table[(c1 & 0x3) << 4])
          res.push('===')
          break
        }
        var c2 = utf8.charCodeAt(i++)
        // 需要补1个=
        if (i === len) {
          res.push(this.table[((c1 & 0x3) << 4) | ((c2 >> 4) & 0x0F)])
          res.push(this.table[(c2 & 0x0F) << 2])
          res.push('=')
          break
        }
        var c3 = utf8.charCodeAt(i++)
        res.push(this.table[((c1 & 0x3) << 4) | ((c2 >> 4) & 0x0F)])
        res.push(this.table[((c2 & 0x0F) << 2) | ((c3 & 0xC0) >> 6)])
        res.push(this.table[c3 & 0x3F])
      }

      return res.join('')
    },
    decode: function (str) {
      if (!str) {
        return ''
      }

      var len = str.length
      var i = 0
      var res = []

      while (i < len) {
        const code1 = this.table.indexOf(str.charAt(i++))
        const code2 = this.table.indexOf(str.charAt(i++))
        const code3 = this.table.indexOf(str.charAt(i++))
        const code4 = this.table.indexOf(str.charAt(i++))

        const c1 = (code1 << 2) | (code2 >> 4)
        const c2 = ((code2 & 0xF) << 4) | (code3 >> 2)
        const c3 = ((code3 & 0x3) << 6) | code4

        res.push(String.fromCharCode(c1))

        if (code3 !== 64) {
          res.push(String.fromCharCode(c2))
        }
        if (code4 !== 64) {
          res.push(String.fromCharCode(c3))
        }
      }

      return this.UTF8ToUTF16(res.join(''))
    }
  },
  setFormData (FlowCtlData, formData) {
    if (FlowCtlData.nextflowactionmsg.nodeMsg.nodeId === 'flowEnd') {
      formData.approvalStatus = 3
    } else if (FlowCtlData.nextflowactionmsg.nodeMsg.nodeId === 'flowStart') {
      formData.approvalStatus = 1
    } else {
      formData.approvalStatus = 2
    }
    if (formData.approvalComplete === null || formData.approvalComplete === undefined) {
      formData.approvalComplete = ''
    }
    if (formData.approval === null || formData.approval === undefined) {
      formData.approval = ''
    }
    formData.approvalComplete = formData.approvalComplete + localStorage.getItem('userName') + ','
    formData.approval = FlowCtlData.nextflowactionmsg.psnMsg.psnId
    if (!formData.approval) {
      formData.approval = ''
    }
    return formData
  },
  alertDialog (This, msg) {
    return This.$confirm(`${msg || ''}下一环节：${this.nextflowactionmsg.nodeMsg.nodeName}。是否提交？`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
  },
  alertBHDialog (This, msg, allMsg) {
    let slartMsg = `${msg || ''}下一环节：${this.nextflowactionmsg.nodeMsg.nodeName}。是否驳回？`
    if (allMsg) {
      slartMsg = allMsg
    }
    return This.$prompt(slartMsg, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      inputPlaceholder: '请填写审批意见',
      type: 'warning'
    })
  },
  formAlert (This, type, message) {
    if (type === 'success') {
      This.$message({
        message: message || '提交成功！',
        type: 'success'
      })
    }
    if (type === 'error') {
      This.$message({
        message: message || '操作失败！',
        type: 'error'
      })
    }
    if (type === 'info') {
      This.$message({
        message: message || '操作已取消！',
        type: 'info'
      })
    }
  },
  async getApprPsn (roleId, roleName) {
    let apprs = ''
    await requestForm('/api/system/role/getUserByRoleId', 'post', { roleId: roleId, roleName: roleName }).then((response) => {
      if (response.code === '200') {
        for (const item of response.data) {
          apprs = apprs + item.username + ';'
        }
      }
    })
    return apprs
  }
}
/* $(document).ready(function () {
  FlowCtlData.initialize()
}) */

export const getApprPsn = async (roleId, roleName) => {
  let apprs = ''
  await requestForm('/api/system/role/getUserByRoleId', 'post', { roleId: roleId, roleName: roleName }).then((response) => {
    if (response.code === '200') {
      for (const item of response.data) {
        apprs = apprs + item.username + ';'
      }
    }
  })
  return apprs
}
